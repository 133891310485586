import React, {useRef, useEffect, useState, useContext} from 'react';
import Logo from "./Logo";
import IconFacebook from "./IconFacebook";
import IconInstagram from "./IconInstagram";
import mapboxgl from '!mapbox-gl';
import Context, { ContextProvider } from "./Context";



mapboxgl.accessToken = 'pk.eyJ1IjoidGhpZWJhdWxkdGdjZCIsImEiOiJjbDFwYmcyN3cwcmp6M2NwZ3VnZHI1NzRiIn0.YvnZMD7FzFLmTNQJ9H5s4Q';


const Footer = ({onClickFooterElement}) => {

    const context = useContext(Context);

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [style] = useState('mapbox://styles/thiebauldtgcd/ckxhpdtsu0gbk14moa326riip');

    const [supportVisible, setSupportVisible] = useState(false);
    const [teamVisible, setTeamVisible] = useState(false);

    useEffect(() => {
        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: style,
            zoom: 16.74,
            bearing: 0.00,
            pitch: 50.00,
            center: [2.403896, 48.808868],
            antialias: true
        });
    });

    return (
        <footer className="main-footer">
            <div ref={mapContainer} className="main-footer__map" />

            <div className="main-footer__container">
                <div className="main-footer__signature">
                    <Logo width={185} height={41} color={'var(--accent-color)'}/>
                    <p>
                        39 Rue Maurice Gunsbourg<br/>
                        94200 Ivry-sur-Seine • France<br/>
                        +33 (0)1 74 02 02 52
                    </p>
                </div>

                <div>
                    <a href="#contact" onClick={(e) => onClickFooterElement('support') } className="button-1">Contact</a>
                </div>

                <div>
                    <ul className="main-footer__social-networks">
                        <li>
                            <a href="https://www.instagram.com/be4post/" title="Instagram"><IconInstagram width={44} height={44} color={'#fff'}/></a>
                            <a href="https://www.facebook.com/be4post" title="Facebook"><IconFacebook width={44} height={44} color={'#fff'}/></a>
                        </li>
                    </ul>
                </div>

                <div className="main-footer__sosumi">
                    © 2021 Be4Post
                    <div>
                        Réalisation <a href="https://www.tgcd.xyz" rel="nofollow">tgcd.xyz</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
