import * as React from "react"
import classNames from "classnames";
import IconClose from "../assets/icon-close.svg";
import {createRef, useEffect} from "react";

const Overlay = ({children, title, onCloseHandler, contentBoxWidth, noSubtitle, noPadding}) => {

    const drawerRef = createRef();

    useEffect(() => {
        if (drawerRef) {
            drawerRef.current.focus();
        }
    }, [drawerRef]);

    const onClickHandler = (e) => {
        e.stopPropagation();
        onCloseHandler()
    }

    const onKeydownHandler = (e) => {
        if (e.key === "Escape") {
            onCloseHandler()
        }
    }

    const style = {
        maxWidth: contentBoxWidth
    }

    const classNameOverlay = classNames(
        'overlay',
        {'overlay--visible': true}
    );

    const classNameTitle = classNames(
        'overlay__title',
        {'overlay__title--no-subtitle': noSubtitle}
    )

    const classNameContent = classNames(
        'overlay__content',
        {'overlay__content--no-padding': noPadding}
    )

    return (
        <div role="dialog" ref={drawerRef} className={classNameOverlay} onClick={onClickHandler} onKeyDown={onKeydownHandler} tabIndex="-1">


            <div role="contentinfo" className={classNameContent} onClick={(e) => e.stopPropagation()} style={style}>
                <div className="overlay__close">
                    <IconClose onClick={onClickHandler} />
                </div>


                {title ?
                    <div className={classNameTitle}>
                        {title}
                    </div>
                    : null}
                <div>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Overlay
