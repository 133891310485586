import * as React from "react"
import {useEffect, useRef, useState} from "react";

import Logo from "./Logo";

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = 'pk.eyJ1IjoidGhpZWJhdWxkdGdjZCIsImEiOiJjbDFwYmcyN3cwcmp6M2NwZ3VnZHI1NzRiIn0.YvnZMD7FzFLmTNQJ9H5s4Q';

const ConfirmationMessage = () => (
    <div className={"message message--success"}>
        Your message has been sent successfully.
    </div>
)

const ContactForm = () => {

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [style] = useState('mapbox://styles/thiebauldtgcd/cl26jlnvq000814lpqw8ucg45');

    useEffect(() => {
        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: style,
            zoom: 16,
            center: [2.405488, 48.811536],
            antialias: true
        });

        map.current.on('load', () => {
// Load an image from an external URL.
            map.current.loadImage(
                'https://i.postimg.cc/X7Yr65r9/marker.png',
                (error, image) => {
                    if (error) throw error;

// Add the image to the map style.
                    map.current.addImage('cat', image);

// Add a data source containing one point feature.
                    map.current.addSource('point', {
                        'type': 'geojson',
                        'data': {
                            'type': 'FeatureCollection',
                            'features': [
                                {
                                    'type': 'Feature',
                                    'geometry': {
                                        'type': 'Point',
                                        'coordinates': [2.404073, 48.811495]
                                    }
                                }
                            ]
                        }
                    });

// Add a layer to use the image to represent the data.
                    map.current.addLayer({
                        'id': 'points',
                        'type': 'symbol',
                        'source': 'point', // reference the data source
                        'layout': {
                            'icon-image': 'cat', // reference the image
                        }
                    });
                }
            );
        });

    });

    const [formSent, setFormSent] = useState(false);

    const handleFormSubmit = (e) => {
        e.preventDefault()
        const form = document.getElementById('contact-form');
        const formData = new FormData(form);
        fetch('/', {
            method: 'POST',
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString()
        }).then(() => {
            setFormSent(true);
            console.log('Form successfully submitted');
        }).catch((error) =>
            alert(error))
    }

    return (
        <div className="row">
            <div className="col-md-5">
                <div className="contact-form-wrapper">
                    <div className="overlay__title">
                        Contact us
                    </div>

                    <div className="overlay__subtitle">
                        Any questions, any suggestions? let us know!
                    </div>

                    {formSent ? <ConfirmationMessage/> : null }
                    {!formSent ? <form id="contact-form" name="contact" method="POST" data-netlify="true" onSubmit={handleFormSubmit}>
                        <input type="hidden" name="form-name" value="contact" />
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="support-company-production">Company / Production</label>
                                    <input type="text" name="company-production" className="form-control" id="support-company-production" required />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="support-first-name">First Name</label>
                                    <input type="text" name="first_name" className="form-control" id="support-first-name" required />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="support-last-name">Last Name</label>
                                    <input type="text" name="last_name" className="form-control" id="support-last-name" required />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="support-phone">Phone number</label>
                                    <input type="text" name="phone" className="form-control" id="support-phone" required />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="support-email">Email address</label>
                                    <input type="email" name="email" className="form-control" id="support-email" required />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="support-subject">Subject</label>
                                    <input type="text" name="subject" className="form-control" id="support-subject" required />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="support-message">Message</label>
                                    <textarea name="message" className="form-control" id="support-message" required rows={3} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-submit-container">
                                    <div>
                                        <button type="submit" className="button-2">Send</button>
                                    </div>
                                    <div>
                                        <p>
                                            By clicking on "Send message", I agree to be contacted by the Be4Post teams and that the data filled in this form be used for update notifications by email.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form> : null }
                </div>
            </div>
            <div className="col-md-7 d-none d-md-block">
                <div className="contact-map-wrapper">
                    <div id="contact-map-box" className="contact-map-wrapper__mapbox" ref={mapContainer} >

                    </div>

                    <div className="contact-map-wrapper__contact">
                        <Logo width={116} height={26} color={'var(--dark-color-1)'}/>
                        <address>
                            39 Rue Maurice Gunsbourg<br/>
                            94200 Ivry sur Seine • France<br/>
                            +33 (0)1 74 02 02 52
                        </address>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactForm;
