import React, { useState } from 'react';
import classNames from "classnames";
import MobileNavigationitem from "./MobileNavigationItem";


const MobileNavigation = ({menu}) => {
    const [menuVisible, setMenuVisible] = useState(false);

    const mobileNavigationClassNames = classNames('mobile-navigation', {'mobile-navigation--visible': menuVisible});

    const mobileNavigationButtonClassNames = classNames('hamburger hamburger--collapse', {'is-active': menuVisible})

    return (
        <nav className={"mobile-navigation-container d-block d-lg-none"}>
            <div className="mobile-navigation-button-wrapper">
                <button className={mobileNavigationButtonClassNames}
                        type="button"
                        onClick={() => setMenuVisible(!menuVisible)}
                        aria-label="Menu"
                        aria-controls="mobile-navigation"
                        aria-expanded="true"
                >
                    <span className="hamburger-box">
                      <span className="hamburger-inner"/>
                    </span>
                </button>
            </div>

            <div id="mobile-navigation" className={mobileNavigationClassNames}>
                <ul>
                    {menu.map((item, index) => {
                        const liClassnames = classNames({'current': item.current});
                        return <li key={index} aria-hidden="true" className={liClassnames} onClick={() => setMenuVisible(!menuVisible)}><MobileNavigationitem {...item} /></li>
                    })}
                </ul>
            </div>
        </nav>
    )
}


export default MobileNavigation;
