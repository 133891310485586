import * as React from "react";

const IconFacebook = ({width, height, color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={width} height={height} viewBox="0 0 44 44">
            <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.83" d="M33 3.67h-5.5a9.17 9.17 0 0 0-9.17 9.16v5.5h-5.5v7.34h5.5v14.66h7.34V25.67h5.5L33 18.33h-7.33v-5.5A1.83 1.83 0 0 1 27.5 11H33V3.67Z"/>
        </svg>
    )
}

export default IconFacebook;
