import React from "react"
import { Link } from "gatsby"
import classNames from "classnames";


const NavigationItemLabel = ({label, sublabel}) => {
    return (
        <>
            <span>{label}</span>
            {sublabel ? <><br/><span>{sublabel}</span></> : null}
        </>
    )
}

const MobileNavigationitem = ({label, sublabel, href, submenuItems, useLinkComponent, onClick}) => {
    return <div className={classNames({'item-has-submenu': (submenuItems && submenuItems.length > 0) })}>{useLinkComponent ?
        <Link to={href} className="mobile-navigation-item" onClick={onClick}>
            <NavigationItemLabel label={label} sublabel={sublabel} />
        </Link> :
        <a href={href} className="mobile-navigation-item" onClick={onClick}>
            <NavigationItemLabel label={label} sublabel={sublabel} />
        </a>}
        {submenuItems && submenuItems.length > 0 ?
            <ul className={"mobile-navigation-item-submenu"}>
                {submenuItems.map((submenuItem, index) =>
                    <li key={index}>
                        <MobileNavigationitem {...submenuItem} />
                    </li>
                )}
            </ul>
            : null}
    </div>;
}

export default MobileNavigationitem;
