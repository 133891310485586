import * as React from "react";

const IconInstagram = ({width, height, color}) => {
    return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 44 44">
        <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.83" d="M31.17 3.67H12.83a9.17 9.17 0 0 0-9.16 9.16v18.34c0 5.06 4.1 9.16 9.16 9.16h18.34c5.06 0 9.16-4.1 9.16-9.16V12.83c0-5.06-4.1-9.16-9.16-9.16Z"/>
        <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.83" d="M29.33 20.84A7.33 7.33 0 1 1 14.83 23a7.33 7.33 0 0 1 14.5-2.16ZM32.08 11.92h.02"/>
    </svg>
    )
}

export default IconInstagram;
