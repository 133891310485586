import React from "react"
import { Link } from "gatsby"


const NavigationItemLabel = ({label, sublabel}) => {
    return (
        <>
            <span>{label}</span>
            {sublabel ? <><br/><span>{sublabel}</span></> : null}
        </>
    )
}

const NavigationItem = ({label, sublabel, href, submenuItems, useLinkComponent, onClick}) => {
    return <div>{useLinkComponent ?
        <Link to={href} className="main-navigation-item" onClick={onClick}>
            <NavigationItemLabel label={label} sublabel={sublabel} />
        </Link> :
        <a href={href} className="main-navigation-item" onClick={onClick}>
            <NavigationItemLabel label={label} sublabel={sublabel} />
        </a>}
        {submenuItems && submenuItems.length > 0 ?
                    <ul className={"navigation-item-submenu"}>
                        {submenuItems.map((submenuItem, index) =>
                            <li key={index}>
                                <NavigationItem {...submenuItem} />
                            </li>
                        )}
                    </ul>
            : null}
    </div>;
}

export default NavigationItem;
