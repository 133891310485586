import React  from 'react';
import Navigation from "./Navigation/Navigation";
import { Link } from "gatsby"
import MobileNavigation from "./MobileNavigation/MobileNavigation";
import Logo from "./Logo";
const Header = ({location, path, menu}) => {
    return (
        <header className='main-header'>
            <div className="container">
                <div className="row">
                    <div className="main-header__logo">
                        <Link to={"/"}><Logo width={207} height={46} color={'var(--text-color)'}/></Link>
                    </div>
                    <div className="main-header__navigation-wrapper">
                        <Navigation location={location} path={path} menu={menu}/>
                        <MobileNavigation location={location} path={path} menu={menu} />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
