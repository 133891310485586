import React from "react"
import NavigationItem from "./NavigationItem";
import classNames from "classnames";

const Navigation = ({menu, className}) => {
    return (
        <ul className={classNames("main-navigation", className)}>
            {menu.map((item, index) => {
                const liClassnames = classNames({'current': item.current});
                return <li key={index} className={liClassnames}><NavigationItem {...item} /></li>
            })}
        </ul>
    )
}

export default Navigation;
