import React from "react";

const overlayStateValues = {
    supportVisible: false,
    teamVisible: false
}

const Context = React.createContext(overlayStateValues);
export const ContextProvider = Context.Provider;
export const ContextConsumer = Context.Consumer;
export default Context;
